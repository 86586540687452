import React from "react"
import Cross from "../../icons/close.svg"
import styles from "./features-table.module.css"

const TableCross = ({ asterisk }) => (
  <div className={styles.tableCross}>
    <Cross />
    {asterisk && <span className={styles.checkAsterisk}>*</span>}
  </div>
);

export default TableCross;