import React from "react"
import TableCheck from "./table-check"
import shared from "../../shared/shared.module.css"
import grid from "../../shared/bootstrap-grid.module.css"
import TableCross from "./table-cross"

const renderTableCell = (row, key, isMobile) => {
  switch (row[key]) {

    // true equates to a check
    case true:
      return (
        <div style={{ display: 'flex', justifyContent: !isMobile ? 'center' : null }}>
          <TableCheck />
        </div>
      )

    // false equates to no check.
    // on mobile table we show a cross
    // to make it more explicit. on desktop
    // the cross makes it to cluttered.
    case false:
      return isMobile ? <TableCross /> : <span>&nbsp;</span>

    default:
      return row[key]
  }
}

const DesktopFeatureTable = ({ tableData: { columns, rows } }) => (
  <table>
    <thead>
      <tr>
        {columns.map((column, i) => (
          <th key={i} width={column.width} style={{ textAlign: 'center' }}>
            {column.label}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {rows.map((row, rowIndex) => (
        <tr key={rowIndex} >
          {columns.map(({ key }, columnIndex) => (
            <td key={columnIndex}>
              {renderTableCell(row, key)}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
)

const MobileFeatureTable = ({ column, rows }) => (
  <table>
    <thead>
      <tr style={{ fontSize: '1.2rem' }}>
        <th colspan="2">{column.label}</th>
      </tr>
    </thead>
    <tbody>
      {rows.map((row, rowIndex) => (
        <tr key={rowIndex}>
          <td>
            {row.feature}
          </td>
          <td>
            {renderTableCell(row, column.key, true)}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

/**
 * On mobile we have multiple tables for each tier to
 * make it more readable and less cluttered.
 */
const MobileFeatureTables = ({ tableData }) => tableData.columns
  .filter(column => column.label) // the empty column is not needed on mobile
  .map((column, i) => (
    <MobileFeatureTable key={i} column={column} rows={tableData.rows} />
  ))

const FeaturesTable = ({ tableData }) => (
  <>
    <div className={`${grid.mt5} ${shared.hiddenMobile}`}>
      <DesktopFeatureTable tableData={tableData} />
    </div>
    <div className={`${grid.mt5} ${shared.hiddenDesktop}`}>
      <MobileFeatureTables tableData={tableData} />
    </div>
  </>
);

export default FeaturesTable;