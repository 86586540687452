import React from "react"
import { Link } from 'gatsby'

// Components
import Layout from "../../../components/layout"
import Band, { BandColors } from "../../../components/band"
import SEO from "../../../components/seo"
import PhoneValidationTopNav from "../../../components/top-nav/phone-validation-top-nav"
import FeaturesTable from "../../../components/features-table/features-table"

// CSS
import grid from "../../../shared/bootstrap-grid.module.css"

const featureTableData = {
  columns: [
    {
      label: '',
      key: 'feature',
      width: '50%'
    },
    {
      label: 'Professional',
      key: 'professional',
      width: '25%'
    }, {
      label: 'Enterprise',
      key: 'enterprise',
      width: '25%'
    }
  ],
  rows: [
    {
      feature: 'Classic',
      professional: false,
      enterprise: true
    },
    {
      feature: 'Lightning',
      professional: true,
      enterprise: true
    },
    {
      feature: 'LWC Related List',
      professional: true,
      enterprise: false
    },
    {
      feature: 'LWC List View',
      professional: true,
      enterprise: false
    },
    {
      feature: 'Record Page Validation Fields',
      professional: false,
      enterprise: true
    },
    {
      feature: 'Standard Related List',
      professional: false,
      enterprise: true
    },
    {
      feature: 'Standard List View',
      professional: false,
      enterprise: true
    },
    {
      feature: 'Apex Batch',
      professional: true,
      enterprise: true
    },
    {
      feature: 'Utility Config App',
      professional: true,
      enterprise: true
    },
    {
      feature: 'On Demand Phone Validation',
      professional: true,
      enterprise: true
    },
  ]
}

const PhoneValidationFeaturesPage = () => (
  <Layout>
    <SEO title="Phone Validation" keywords={[`tigerface`, `systems`, `salesforce`, `products`, `phone validation`]} />

    <PhoneValidationTopNav />

    <Band>
      <h1>Features</h1>
      <p>
        Phone Validation is an AppExchange product for Salesforce Lightning and Classic which
        allows users to validate phone numbers on Account, Contact, Lead, and other Standard or
        Custom Object records. The solution utilizes Application Programming Interfaces (APIs)
        published by realphonevalidation.com.
      </p>

      <p>
        A paid account from <a href="https://realphonevalidation.com/">realphonevalidation.com</a> is required for the use of these API services.
      </p>
      <p>
        The following realphonevalidation.com APIs are supported:
      </p>
      <ul>
        <li>Carrier Lookup</li>
        <li>DNC Lookup</li>
        <li>DNC Plus</li>
        <li>RPV Active</li>
        <li>RPV Turbo</li>
        <li>RPV Scrub</li>
        <li>RPV US Premium</li>
        <li>Wireless ID</li>
      </ul>
    </Band>

    <Band color={BandColors.gray}>
      <h2>Feature Breakdown</h2>
      <p>Phone Validation is offered in two tiers:</p>
      <FeaturesTable tableData={featureTableData} />
    </Band>

    <Band>
      <h1>Pricing</h1>

      <div className={`${grid.row} ${grid.mt5}`}>
        <div className={`${grid.colMd6} ${grid.pr5}`} style={{ borderRight: '1px solid #ddd' }}>
          <h2>Professional</h2>
          <p>$64.99 site wide per month or $695.99 site wide per year.</p>
          <p><b>NOTE:</b> site wide provides access to all users within your Salesforce instance.</p>

        </div>
        <div className={`${grid.colMd6} ${grid.plMd5} ${grid.mt4} ${grid.mtMd0}`}>
          <h2>Enterprise</h2>
          <Link to="/contact/" >Contact Us</Link> for pricing.
        </div>
      </div>
    </Band>
  </Layout>
);

export default PhoneValidationFeaturesPage;