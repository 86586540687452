import React from "react"
import Check from "../../icons/check.svg"
import styles from "./features-table.module.css"

const TableCheck = ({ asterisk }) => (
  <div className={styles.tableCheck}>
    <Check />
    {asterisk && <span className={styles.checkAsterisk}>*</span>}
  </div>
);

export default TableCheck;